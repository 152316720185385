#vanta {
    min-height: 100dvh;
    width: 100%;
    position: fixed;
    z-index: -5;
    top: 0;
    left: 0;
}






