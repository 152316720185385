.about-section {
    min-height: 100vh; 
    height: auto;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center; 
    overflow: scroll;
    scrollbar-width: none;
    box-sizing: border-box;
}


.about {
    min-height: auto;
    height: auto;
    width: 100%;
    max-width: 85rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
    box-sizing: border-box;
}

.selfie {
    width: 25rem;
    align-self: center;
    z-index: 15;

    max-width: 100%;
    box-sizing: border-box;
}

.about-paragraph {
    color: #fff;
    padding: 1rem;
    /* border-radius: 2rem; */
    border: none;
    margin: 1rem 0 5rem;
    scrollbar-width: none;
    /* other styles */
    background: rgba(39, 39, 39, 0.1);
    backdrop-filter: blur(30px);
     /* fallback for old browsers */
    -webkit-backdrop-filter: blur(60px);
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
}


@media only screen and (max-width: 65rem) {
    .about-section {
        min-height: 100vh;
        
    }
    
    .about {
        min-height: 100vh;
    }
}

@media only screen and (max-width: 27rem) {
    .about-paragraph {
        margin: .5rem;
    }

    .selfie {
        width: 15rem;
    }

    .about {
        height: 100%;
        padding: 1rem 0 5rem 0;
        margin: 0;
    }

  
    
    
    
}