.cursor {
    animation: blink 1s infinite;
  }
  
  @keyframes blink {
    0% { opacity: 1; } 
    50% { opacity: 0; }
    100% { opacity: 1; }
  }

*{
     z-index: 5; /*vanta background overlaps content */
}

.hero-section {
    height: 100vh;
    height: 100dvh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  
}

.hero-section h1 {
    color:#CAD194;
    font-size: 6rem;
    margin: 0;
    transition: 0.5s;
    text-align: center;
    
}

.hero-section h1:hover {
    -webkit-text-stroke: 2px #fff;
    color: transparent;
    cursor: default;
}

.hero-section h4 {
    color: #fff;
    font-size: 1.25rem;
    margin: 0;
}

.hero-section h4:hover {
    -webkit-text-stroke: .5px #fff;
    color: transparent;
    cursor: default;
}


